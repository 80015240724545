
const router_modular = [
    {
        path: '/index',
        name: 'home',
        component: () => import('@/views/HomeView')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import('@/views/AboutView')
    }
]
export default router_modular


// 1111